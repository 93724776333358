<template>
  <b-card>
    <!-- form -->
    <b-form
      @submit.prevent="onSubmit"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            :label="$t('users.old_password')"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old_password"
                :type="passwordFieldTypeOld"
                placeholder="Old Password"
                @keyup="onChange"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            :label="$t('users.new_password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new_password"
                placeholder="New Password"
                @keyup="onChange"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            :label="$t('users.new_password_retype')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="retypePassword"
                :type="passwordFieldTypeRetype"
                name="new_password_confirmation"
                placeholder="New Password"
                @keyup="onChange"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <b-col cols="12">
          <span class="alert-success">
            {{ passwordChangedMesssage }}
          </span>
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            type="submit"
            :disabled="disabledSend"
          >
            {{ $t('Save changes') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            {{ $t('reset_form') }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import useProfileSettings from './useProfileSettings'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup() {
    const passwordValueOld = ref(null)
    const newPasswordValue = ref(null)
    const retypePassword = ref(null)
    const passwordFieldTypeOld = ref('password')
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')
    const disabledSend = ref(true)
    const passwordChangedMesssage = ref(null)

    const { changePassword } = useProfileSettings()

    const onChange = () => {
      passwordChangedMesssage.value = null

      if (newPasswordValue.value !== ''
          && newPasswordValue.value === retypePassword.value
          && newPasswordValue.value.length > 7
          && passwordValueOld.value !== '') {
        disabledSend.value = false
      } else {
        disabledSend.value = true
      }
    }

    const onSubmit = async () => {
      const params = {}

      if (passwordValueOld.value === null) {
        return false
      }

      if (newPasswordValue.value !== ''
          && newPasswordValue.value !== retypePassword.value
      ) {
        return false
      }

      params.old_password = passwordValueOld.value
      params.password = newPasswordValue.value
      params.password_confirmation = retypePassword.value

      await changePassword(params).then(async response => {
        if (response.status === 200) {
          passwordChangedMesssage.value = i18n.t('users.password_changed_ok')
          retypePassword.value = null
          newPasswordValue.value = null
          passwordValueOld.value = null
          disabledSend.value = true
        } else {
          passwordChangedMesssage.value = i18n.t('users.password_not_changed')
        }
      })

      return false
    }

    return {
      newPasswordValue,
      retypePassword,
      passwordValueOld,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,
      disabledSend,
      onChange,
      passwordChangedMesssage,
      onSubmit,
    }
  },
}
</script>
