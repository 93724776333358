<template>
  <b-overlay
    :show="saving"
    variant="transparent"
    opacity="1"
    blur="5px"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card>
      <b-row>
        <b-col sm="12">
          <b-avatar
            size="lg"
            :src="userData.avatar"
          />

          <b-form-group
            label="avatar"
            label-for="register-avatar"
          >
            <b-form-file
              id="register-avatar"
              :placeholder="$t('Select a file to upload') "
              drop-placeholder="Drop file here"
              @change="handleFileUpload($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('users.name')"
              label-for="name"
            >
              <b-form-input
                v-model="userData.name"
                name="name"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.surname')"
              label-for="surname"
            >
              <b-form-input
                v-model="userData.surname"
                name="surname"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.email')"
              label-for="email"
            >
              <b-form-input
                v-model="userData.email"
                name="email"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.legal_id')"
              label-for="legalId"
            >
              <b-form-input
                v-model="userData.legal_id"
                name="legalId"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.address1')"
              label-for="address1"
            >
              <b-form-input
                v-model="userData.address1"
                name="address1"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.address2')"
              label-for="address2"
            >
              <b-form-input
                v-model="userData.address2"
                name="address2"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.postal_code')"
              label-for="postalCode"
            >
              <b-form-input
                v-model="userData.postal_code"
                name="postalCode"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.city')"
              label-for="city"
            >
              <b-form-input
                v-model="userData.city"
                name="city"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.state')"
              label-for="state"
            >
              <b-form-input
                v-model="userData.state"
                name="state"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('users.telephone')"
              label-for="telephone"
            >
              <b-form-input
                v-model="userData.telephone"
                name="telephone"
                @keyup="onChange"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="alert-success">
              {{ updateMessage }}
            </span>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="disableSaveButton"
              @click.prevent="saveChanges"
            >
              {{ $t('Save changes') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('reset_form') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BOverlay, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import useProfileSettings from './useProfileSettings'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const saving = ref(false)
    const disableSaveButton = ref(false)
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const profileFile = ref(null)
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const updateMessage = ref(null)

    let avatar = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    const { savePersonalData, delay } = useProfileSettings()

    const onChange = () => {
      disableSaveButton.value = false
      updateMessage.value = null
    }

    const handleFileUpload = e => {
      avatar = e.target.files[0]
    }

    const saveChanges = async () => {
      saving.value = true

      const formData = {}
      formData.userData = userData.value
      formData.newAvatar = avatar

      await savePersonalData(formData).then(async response => {
        if (response.status === 200) {
          updateMessage.value = i18n.t('users.update_successful')
        }
      })

      saving.value = false

      await delay(3000)

      updateMessage.value = null
    }

    const resetForm = () => {
      userData.value = JSON.parse(localStorage.getItem('userData'))
    }

    return {
      saving,
      refInputEl,
      previewEl,
      inputImageRenderer,
      disableSaveButton,
      resetForm,
      userData,
      profileFile,
      saveChanges,
      onChange,
      updateMessage,
      avatar,
     // avatarFile,
      handleFileUpload,
    }
  },
}
</script>
